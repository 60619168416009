import toast from "react-hot-toast";
import http, { imgUrl } from "../components/http";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import Details from "../components/details";
import { Link } from "react-router-dom";
import Pagination from "../components/pagination";
import { GetLease } from "../data/lease";

const handleDelete = async ({ id, photo }) => {
  try {
    await http.delete(`lease/${id}?file=${photo}`);
    toast.success("Property Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

function LeaseTable({ market }) {
  const { currentUser } = useContext(AuthContext);
  const [data, setPosts] = useState([]);
  const { firm } = currentUser;
  //const { properties, prLoading,prerror } = useSearch(currentUser.firm, query)

  {
    useEffect(() => {
      const fetchPosts = async () => {
        //  const res = await axios.get("/posts");
        const res = await http.get(`lease/${firm}`);
        setPosts(res.data);
      };
      fetchPosts();
    }, []);
  }

  let num = 1;
  //const { error, isLoading, data } = GetLease();
  const [currentPage, setCurrentPage] = useState(1);

  // if (isLoading) return <p>{"List is loading"}</p>;

  // if (error) return <p>{"Error in Connection"}</p>;
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);

  // console.log(properties)
  return (
    <div>
      <Details
        th1={"Total Properties"}
        total={data?.length}
        th2={"Total Cities"}
        total2={3}
      />
      {/* Add new payment */}
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          We are Growing,{" "}
          <strong>
            <Link to="/addLease" class="blue-text">
              List the new Vacant property here
            </Link>
          </strong>
        </p>
      </div>

      <table class="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                # <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Property <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                City <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                rent <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Video <span class="caret"></span>
              </a>
            </td>
            <td> Fees</td>
            <td>Edit</td>

            <td>Delete</td>
          </tr>
        </thead>
        {records &&
          records.map((property, key) => {
            return (
              <tbody>
                <tr key={key}>
                  <td>{num++}</td>
                  <td>
                    <Link
                      to={`/property/${property.slug}`}
                      state={property}
                      className="templatemo-link"
                    >
                      {property.propertyName}
                    </Link>
                  </td>
                  <td>{property.city}</td>
                  <td>{property.rent}</td>
                  <td>
                    <video
                      src={imgUrl + property.photo}
                      height={100}
                      width={100}
                      controls
                    >
                      Video
                    </video>
                  </td>
                  <td>{property.fees}</td>
                  <td>
                    <Link
                      to={`/addLease`}
                      state={property}
                      class="templatemo-edit-btn"
                    >
                      {" "}
                      Edit
                    </Link>
                  </td>

                  <td>
                    <Link
                      to=""
                      onClick={() => handleDelete(property)}
                      class="templatemo-edit-btn"
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              </tbody>
            );
          })}
      </table>
      {/* Pagination */}
      <Pagination
        order={data.length}
        recordsPerPage={recordsPerPage}
        paginate={paginate}
      />
    </div>
  );
}

export default LeaseTable;
