import React, { useContext } from "react";
import { PaystackButton } from "react-paystack";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import http from "./http";
import toast from "react-hot-toast";

function Paystack({ option }) {
  const { currentUser } = useContext(AuthContext);
  const { email, firm } = currentUser;
  const { value, rate, number } = option;
  const numbers = value && parseInt(value);
  const total = Math.round(numbers * 100000);
  const subcription = moment().add(number, "month").format();
  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: total, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_1e5683794a7e3b1a5aa45a54ecd9642ca385b25a",
  };

  // you can call this function anything
  const handlePaystackSuccessAction = async (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    const data = new FormData();
    data.append("subscription", subcription);
    try {
      const res = await http.put(`market/sub/${firm}`, data);
      toast.success(res.data);
      window.location.replace("/");
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: `${value}-${rate}`,
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <div>
      <hr />
      <PaystackButton {...componentProps} />
    </div>
  );
}

export default Paystack;
