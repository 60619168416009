import React, { useContext } from "react";
import Modal from "react-modal";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function SubModal(props) {
  const { currentUser } = useContext(AuthContext);
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    window.location.replace("/pay-options");
  }
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>You have no active subscription</div>
        <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
          <div className="form-group">
            <button
              onClick={closeModal}
              className="templatemo-blue-button width-100"
            >
              Renew
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SubModal;
