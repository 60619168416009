import React from "react";
import Latestpayment from "./latestPayment";
import Subscription from "./subscription";

export default function Describe2() {
  return (
    <div class="templatemo-flex-row flex-content-row">
      <div class="col-1">
        <div class="templatemo-content-widget white-bg">
          <i class="fa fa-times"></i>
          <div class="media">
            <div class="media-left">
              <a href="#"></a>
            </div>
            <div class="media-body">
              <h2 class="media-heading text-uppercase">News and Articles</h2>
              <p>
                Read Up topical issues and News on property management from our
                blog. Click here
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <Latestpayment />
      </div>
    </div>
  );
}
