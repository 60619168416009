import Footer from "../components/footer";
import { imgUrl } from "../components/http";
import Nav from "../components/nav";
import parse from "html-react-parser";

import { Link, useLocation } from "react-router-dom";

function Article() {
  // To parse data

  const state = useLocation().state;
  const photo = imgUrl + state.photo;
  return (
    <>
      <div class="templatemo-flex-row">
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
            <div class="templatemo-flex-row flex-content-row">
              <div class="templatemo-flex-row flex-content-row">
                <div class="templatemo-content-widget white-bg col-2">
                  <i class="fa fa-times"></i>
                  <div class="media margin-bottom-30">
                    <div class="media-body">
                      <h2 class="media-heading text-uppercase blue-text">
                        {state.title}{" "}
                      </h2>
                      <p>{state.slug}</p>
                    </div>
                  </div>
                </div>
                <div class="templatemo-content-widget white-bg col-1 templatemo-position-relative templatemo-content-img-bg">
                  <img
                    src={photo}
                    alt="Sunset"
                    className="img- content-bg-img"
                  />
                  <i class="fa fa-heart"></i>
                  <h2 class="templatemo-position-relative white-text">
                    Sunset
                  </h2>
                  <div class="view-img-btn-wrap">
                    <a href="" class="btn btn-default templatemo-view-img-btn">
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1">
              <div class="templatemo-content-widget orange-bg">
                <i class="fa fa-times"></i>
                <div class="media">
                  <div class="media-body">
                    <h2 class="media-heading text-uppercase">Description</h2>
                    <p>{state.description}</p>
                  </div>
                </div>
              </div>
              <div class="templatemo-content-widget white-bg">
                <i class="fa fa-times"></i>
                <div class="media">
                  <div class="media-body">
                    <h2 class="media-heading text-uppercase"> Body</h2>
                    <p>{parse(state.body)} </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <Link to={`/post/`} state={state}>
                <button type="submit" class="templatemo-blue-button width-10">
                  Update
                </button>{" "}
              </Link>
            </div>
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-10">
                Delete
              </button>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Article;
