import Footer from "../components/footer";
import { React, useEffect, useState } from "react";

import { isTokenExpired } from "../components/checkToken";
import LoginForm from "../components/loginForm";
import { useLocation } from "react-router-dom";
import http, { Url } from "../components/http";
import Loading from "../components/loader";
//var createGuest = require('cross-domain-storage/guest');
//var bazStorage = createGuest(window.location.href ==='http://localhost:3001'|| 'http://localhost:3000');

function Login(props) {
  const [details, setDetails] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //bazStorage.get('users', function(error, value) {
  // console.log( value)
  // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
  //});
  // const {email,setEmail,setOTP,setPage} = RecoverPassword()
  //Toggle Password

  const location = useLocation();

  const redirect = location.search;
  const token = redirect && redirect?.slice(10);

  // const {email, role,username, firm} = jwt.verify(query,process.env.JWT_SECRET_KEY)
  //  console.log(email)
  //

  // return alert('Please enter your email')

  return (
    <>
      {token ? (
        <Loading token={token} />
      ) : (
        <div className="templatemo-content col-1 light-gray-bg">
          {/*<LoginForm />*/}
          <>
            <div className="templatemo-content-widget templatemo-login-widget white-bg">
              <header className="text-center">
                <div className="square"></div>
                <h1>Rent And Flex</h1>
                <p>Excellent Property Managers</p>
              </header>

              <div className="form-group">
                <button
                  onClick={window.location.replace(
                    "https://rentandflex.com/auth/sign_in"
                  )}
                  type="submit"
                  className="templatemo-blue-button width-100"
                >
                  Click to Login
                </button>
              </div>
            </div>
            <Footer />
          </>
        </div>
      )}
    </>
  );
}

export default Login;
