import React, { useState, useContext } from "react";
import Nav from "../components/nav";
import http, { tenantUrlEndpoint } from "../components/http";
//import DatePicker from "react-datepicker";
import Datetime from "react-datetime";

import { Link, useLocation } from "react-router-dom";
//import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AuthContext } from "../context/authContext";
import useSWR from "swr";
import toast from "react-hot-toast";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

function AddRent() {
  const post = useLocation().state;
  //Fetch params
  const location = useLocation();
  const propertyId = location.pathname.split("/")[2];
  const { currentUser } = useContext(AuthContext);
  let endPoint = tenantUrlEndpoint + currentUser.firm;
  const { isLoading, data: posts, error } = useSWR(endPoint, fetcher);

  const [tenantName, setTenant] = useState(post?.tenantName || "");

  //console.log(tenantName, propertyId);

  const [payment, rentRef] = useState(post?.payment || "");

  const [tenure, setTenure] = useState("");

  const [errors, setErrors] = useState(false);
  const [duration, setDuration] = useState("");
  // console.log(duration);
  // Date state
  const [startDat, setStartDate] = useState(new Date());
  //const [endDate, setEndDate] = useState(post?.startDate || new Date());
  let startDate = startDat._d || post?.startDate;
  const rentDate = moment(startDat, "MM/DD/YYYY");
  let rentdoc = rentDate.clone();
  //EndDate
  let enDate = rentdoc.add(duration, tenure);
  const endDate = enDate._d;

  let today = moment().format("DD MMMM YYYY");
  let recordDate = today;

  //Paid date state
  const [pDate, setPaidDate] = useState(new Date());
  let padate = moment(pDate, "MM/DD/YYYY");
  const paidDate = post?.paidDate || padate._d;

  const durations = duration + tenure;
  //submit handler
  const writeHandler = async (e) => {
    e.preventDefault();
    setErrors(false);

    const postItems = {
      tenantName,
      propertyId,
      payment,
      startDate,
      endDate,
      paidDate,
      recordDate,

      firm: currentUser.firm,
      username: currentUser.username,
      durations,
    };
    try {
      //console.log(postItems);

      // const res = await axios.post("/posts", {
      post
        ? await http.put(`/payment/${post.id}`, {
            ...post,
            tenantName,
            propertyId,
            payment,
            startDate,
            endDate,
            paidDate,
            recordDate,
            firm: currentUser.firm,
            username: currentUser.username,
            durations,
          })
        : await http.post(`payment`, postItems);
      window.location.replace("/property");
      toast.success("Added Successfully");
    } catch (err) {
      setErrors(true);
    }
  };
  if (isLoading) return <p>{"List is loading"}</p>;

  if (error) return <p>{"Error in Connection"}</p>;
  const handler = (id) => {
    return posts.filter((tenant) => tenant.property === id);
  };
  const tenant = handler(propertyId) || 0;
  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>
              You are a MarketPlace. We make it easier for your online customers
              to buy from your shop.
            </p>
          </header>
          {errors && (
            <span style={{ color: "red" }}> Post is unsuccessful</span>
          )}
          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            {error && <p>{error.message}</p>}
            <div class="form-group">
              <div className="">
                <div>
                  <span>Select Tenant Name</span>
                </div>

                <select
                  onChange={(e) => setTenant(e.target.value)}
                  class="form-control"
                >
                  {tenant
                    ? tenant.map((option, key) => (
                        <>
                          <option value="" selected="selected" hidden="hidden">
                            {post ? post.tenantName : "Choose here"}
                          </option>
                          <option key={key} value={option.name}>
                            {option.name}
                          </option>
                        </>
                      ))
                    : isLoading && <p>Tenants Name are Loading...</p>}
                </select>
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox squaredTwo">
                <label>
                  <span>Date of Payment</span>
                </label>
                <Datetime
                  value={paidDate}
                  onChange={(date) => setPaidDate(date)}
                  dateFormat="D MMMM, YYYY"
                  timeFormat={false}
                />
              </div>
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                name="rent"
                id="rent"
                value={payment}
                placeholder="Enter Payment"
                required
                onChange={(e) => rentRef(e.target.value)}
              />
            </div>
            {/* Date Time */}
            <div class="form-group">
              <div class="checkbox squaredTwo">
                <label>
                  <span>Start Date</span>
                </label>
                <Datetime
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="D MMMM, YYYY"
                  timeFormat={false}
                />
              </div>
            </div>
            {/* Date Time 
            <div class="form-group">
              <label htmlFor="start-date">
                <span></span>Start Date:
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Select Start Time"
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>*/}
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                name="duration"
                placeholder="Duration"
                value={duration}
                required
                onChange={(e) => setDuration(e.target.value)}
              />
            </div>
            <div class="form-group">
              <div class="checkbox squaredOne">
                <input
                  type="radio"
                  value="months"
                  name="duration"
                  id="month"
                  onChange={(e) => setTenure(e.target.value)}
                />
                <label htmlFor="month">
                  <span></span>Month(s)
                </label>
              </div>
            </div>
            <div class="form-group">
              <div class="checkbox squaredTwo">
                <input
                  type="radio"
                  value="years"
                  name="duration"
                  id="year"
                  onChange={(e) => setTenure(e.target.value)}
                />
                <label htmlFor="year">
                  <span></span>Year(s)
                </label>
              </div>
            </div>
            {/* <div class="form-group">
              <label htmlFor="start-date">
                <span></span>End Date:
              </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="Select Start Time"
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>*/}
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
          <span>
            Go back to <Link to="/property">Property</Link>
          </span>{" "}
        </div>
      </div>
    </>
  );
}

export default AddRent;
