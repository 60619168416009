import React, { useState, useContext, useRef } from "react";
import Nav from "../components/nav";
import { AuthContext } from "../context/authContext";
import { mutate } from "swr";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import http, { letOptions } from "../components/http.jsx";

function AddLease() {
  const post = useLocation().state;
  const { currentUser } = useContext(AuthContext);

  //picture states
  const [file, selectedFile] = useState([]);
  const [error, setError] = useState(false);

  const formRef = useRef();

  var fileSelectedHandler = (event) => {
    console.log(event.target.files[0]);
    const maxAllowedSize = 2 * 1024 * 1024;
    if (event.target.files[0]?.size > maxAllowedSize) {
      // Here you can ask your users to load correct file
      event.target.value = "";
    } else {
      selectedFile(event.target.files[0]);
    }
  };

  //Upload Images
  const upload = async () => {
    try {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      console.log(data);
      const attachPics = await http.post(`upload`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return attachPics.data;
    } catch (error) {
      setError(true);
      console.log(error.response);
    }
  };

  //submit handler

  const writeHandler = async (e) => {
    e.preventDefault();

    const propertyName =
      formRef.current.propertyName.value || post?.propertyName;
    const facilities = formRef.current.facilities.value || post?.facilities;
    const fees = formRef.current.fees.value || post?.fees;
    const rent = formRef.current.rent.value || post?.rent;
    const type = formRef.current.type.value || post?.type;
    const city = formRef.current.city.value || post?.city;

    const img = await upload();
    setError(false);
    const postItems = {
      propertyName,
      username: currentUser.username,
      firm: currentUser.firm,
      facilities,
      fees,
      city,
      type,
      rent,
      photo: file ? img : "",
    };
    console.log(postItems);
    try {
      post
        ? await http.put(`/lease/${post.id}`, {
            ...post,
            propertyName,
            username: currentUser.username,
            firm: currentUser.firm,
            facilities,
            fees,
            city,
            type,
            rent,
            photo: file ? img : "",
          })
        : await http.post(`lease`, postItems);
      mutate();

      toast.success("Property Added Successfully");
      // window.location.replace("/property");
    } catch (error) {
      console.log(error);
      setError(true);
      toast.error(error.message, {
        duration: 100,
      });
    }
  };
  //CSV

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>Lease wth Ease </p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
            ref={formRef}
          >
            <label>
              <span>Enter Address of Property </span>
            </label>
            <div class="form-group">
              <input
                class="form-control"
                type="name"
                name="propertyName"
                placeholder="Enter Address of Property"
                value={post?.propertyName}
                required="true"
              />
            </div>
            <label>
              <span>Enter Annual Rent </span>
            </label>
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                name="rent"
                placeholder="Enter Annual Rent"
                value={post?.rent}
                required
              />
            </div>
            <label>
              <span>Enter City </span>
            </label>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="city"
                value={post?.city}
                placeholder="City"
                required
              />
            </div>
            <label>
              <span>Describe Available Facilities </span>
            </label>
            <div class="form-group">
              <textarea
                type="text"
                class="form-control"
                name="facilities"
                value={post?.facilities}
                placeholder="Describe Property and Facilities in the Premises"
                required="true"
              />
            </div>
            <label>
              <span>Enter Other Fees</span>
            </label>
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                name="fees"
                placeholder="Enter Other Fees"
                value={post?.fees}
                required
              />
            </div>

            <div className="form-group">
              <select name="type" className="form-control" value={post?.type}>
                {letOptions ? (
                  letOptions.map((option, key) => (
                    <>
                      {" "}
                      <option value="" defaultValue="selected" hidden="hidden">
                        Choose Apartment Type
                      </option>
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    </>
                  ))
                ) : (
                  <p>Options are are Loading...</p>
                )}
              </select>
            </div>
            <label>
              <span>Select Video of less than 2Mb</span>
            </label>
            <div class="form-group">
              <input
                class="form-control"
                type="file"
                className="subscription-input-form"
                name="file"
                accept=".mkv,.mp4"
                onChange={fileSelectedHandler}
              />
            </div>
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddLease;
