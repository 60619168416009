import React from "react";
import { Link } from "react-router-dom";
import Paystack from "./paystack";

export default function PayCard({ title, style, options }) {
  // you can call this function anything

  return (
    <>
      {" "}
      <div className="templatemo-content-widget white-bg col-2">
        <i className="fa fa-times"></i>
        <div className={`circle ${style}-bg`}></div>

        <h2 className="templatemo-inline-block">{title}</h2>
        {options.map((option) => (
          <div className="table-responsive">
            <table className="table">
              <tbody>
                <Link className="templatemo-link">
                  <tr>
                    <td>
                      <Paystack option={option} />
                    </td>
                  </tr>
                </Link>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>
  );
}
