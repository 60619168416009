import axios from "axios";

export default axios.create({
  //baseURL: "http://localhost:7000/api/",

  baseURL: "https://cloud.rentandflex.com/api/",
  //withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
export const Url = "https://cloud.rentandflex.com/api/";
//export const Url = "http://localhost:7000/api/";
export const imgUrl = "https://cloud.rentandflex.com/images/";
//export const imgUrl = "http://localhost:7000/images/";

export const propertyUrlEndpoint = Url + "property/";
export const defaultUrlEndpoint = Url + "default/";

export const paymentUrlEndpoint = Url + "payment/";
export const tenantUrlEndpoint = Url + "tenant/";
export const marketUrlEndpoint = Url + "market/";
export const postUrlEndpoint = Url + "posts/";

export const userUrlEndpoint = Url + "users/";
export const newuserUrlEndpoint = Url + "newusers/";
export const searchUrl = Url + "search/";

export const fetcher = (...args) =>
  fetch(...args).then((response) => response.json());

//export const defaultHandler = (payment, currentDate) => {
//return payment.filter((f) => moment(f.endDate).isBefore(currentDate));};

export const letOptions = [
  { label: "All", value: "" },
  { label: "Office", value: "office" },
  { label: "Shop", value: "shop" },
  { label: "Warehouse", value: "warehouse" },
  { label: "Suites", value: "suites" },

  { label: "Single rooms", value: "single rooms" },
  { label: "Room Selfcon", value: "room selfcon" },
  { label: "2 Bedroom", value: "2 bedroom" },
  { label: "3 Bedroom", value: "3 bedroom" },
  { label: "4 bedroom", value: "4 bedroom" },
  { label: "Bungalow", value: "bungalow" },
];
