import React from "react";
import LeaseTable from "../list/leaseTable";
import Sidebar from "../components/sideBar";
import Nav from "../components/nav";

export default function ToLets() {
  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
            <div class="templatemo-content-widget no-padding">
              <div class="panel panel-default table-responsive">
                <LeaseTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
