import React from "react";
import { imgUrl } from "../components/http";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";
import { useMarket } from "../data/market";
import Subscription from "./subscription";

function MarketProfile(props) {
  const { currentUser } = useContext(AuthContext);
  const { mkLoading, market, mkerror } = useMarket(currentUser.firm);
  if (mkLoading) return <p>{"Data is fetching"}</p>;

  //let expireRents = defaultHandler(payment, currentDate);
  if (mkerror) return <p>{"Failed to fetch"}</p>;
  return (
    <div className="templatemo-flex-row flex-content-row">
      <div className="templatemo-content-widget white-bg col-2">
        <i className="fa fa-times"></i>
        <div className="square"></div>
        <h2 className="text-uppercase">{market && market.title}</h2>

        <hr />
        <p>{market && market.description} </p>
      </div>
      <div className="templatemo-content-widget white-bg col-1 text-center">
        <i className="fa fa-times"></i>
        <h2 className="templatemo-inline-block">Public Photo</h2>
        <h3 className="text-lowercase margin-bottom-10"></h3>
        <img
          src={market && imgUrl + market.photo}
          alt="Bicycle"
          className="img-circle img-thumbnail"
        />
      </div>
      <Subscription sub={market?.subscriptionEnd} />
    </div>
  );
}

export default MarketProfile;
