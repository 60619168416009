import { createContext, useState, useEffect, useContext } from "react";
import http from "../components/http";
import Login from "../pages/login";
import ResetPassword from "../resetPassword/resetEmail";
import ConfirmPassword from "../resetPassword/confirmPassword";
import Recovered from "../resetPassword/Recovered";
import { useLocation } from "react-router-dom";
import { isTokenExpired } from "../components/checkToken";
import toast from "react-hot-toast";
import axios from "axios";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState("login");
  const [token, setToken] = useState("");
  const [otp, setOTP] = useState("");

  const login = async (inputs) => {
    try {
      const res = await http.post("/auth/login", inputs);
      console.log(res);
      setCurrentUser(res.data.user);
    } catch (err) {
      //
      toast.error(err.message);
    }
  };

  const redirect = async (token) => {
    try {
      const res = await http.post(`users/${token}`);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setCurrentUser(res.data);
      setToken(token);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  {
    /*function NavigateComponents(){
    if(page ==='login') return <Login/>
    if(page ==='otp') return <ResetPassword/>
    if(page==='reset') return <ConfirmPassword/>
  return <Recovered/>    
  }*/
  }

  const logOut = async () => {
    await http.post("/auth/logout");
    setCurrentUser(null);
    axios.defaults.headers.common["Authorization"] = "";
  };

  useEffect(() => {
    sessionStorage.setItem("user", JSON.stringify(currentUser));
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }, [currentUser]);

  {
    /*useEffect(() => {
    const intervalId = setInterval(async () => {
      const token = await SecureStore.getItemAsync(TOKEN_KEY);
      if (token) {
        const tokenDetails = JSON.parse(token);
        if (JSON.stringify(user) !== JSON.stringify(tokenDetails.user)) {
          setUser(tokenDetails.user); // Update the user in context
        }
      }
    }, 5000); // Poll every 5 seconds (adjust as needed)

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [user]);*/
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        redirect,
        login,
        logOut,
        page,
        setPage,

        otp,
        setOTP,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
