import React, { useContext, useEffect, useState } from 'react'
import http from './http'
import toast from 'react-hot-toast'
import { AuthContext } from '../context/authContext';

export default function Loading({token}) {
    const { redirect } = useContext(AuthContext);
  
  token && redirect(token)
      
  return (
    <div class="col-1">              
              <div class="templatemo-content-widget orange-bg">
                <i class="fa fa-times"></i>                
                <div class="media">
                  <div class="media-left">
                    <a href="#">
                    </a>
                  </div>
                  <div class="media-body">
                  <h2 className="media-heading text-uppercase">Redirecting to App ....</h2>
                  <p></p></div>        
                </div>   
</div>
</div>
  )
}
